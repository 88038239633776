<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="_id"
    show-expand
  >
    <template v-slot:group.header="{ group, headers }">
      <td class="primary white--text" :colspan="headers.length">
        {{ group || 'Rotinas gerais' }}
      </td>
    </template>

    <template v-slot:item.action="{ item }">
      <v-btn text icon color="green" small @click="() => $emit('click:run', item)" :disabled="item.status === 'running'">
        <v-icon>mdi-play-circle-outline</v-icon>
      </v-btn>

      <v-btn text icon color="blue" small @click="() => $emit('click:edit', item)">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <v-btn text icon color="red" small @click="() => $emit('click:remove', item)">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip :color="getStatusColor(item.status)" small label>
        {{ getStatusText(item.status) }}
      </v-chip>
    </template>

    <template v-slot:item.cron="{ item }">
      <v-tooltip right>
        <template v-slot:activator="{ on: tooltipListeners, attrs: tooltipAttrs }">
          <v-icon
            small
            left
            :color="item.active ? 'primary' : 'error'"
            v-on="tooltipListeners"
            v-bind="tooltipAttrs"
          >
            {{ item.active ? 'mdi-alarm' : 'mdi-alarm-off' }}
          </v-icon>
        </template>

        <template v-slot:default>
          {{ item.active ? 'Rotina agendada' : 'Rotina desligada' }}
        </template>
      </v-tooltip>

      <code>{{ item.cron }}</code>
    </template>

    <template v-slot:item.lastRunAt="{ item }">
      {{ formatDate(item.lastRunAt) || 'Nunca executado' }}
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-2">
        <job-details-card :data="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import JobDetailsCard from './JobDetailsCard.vue';
import { STATUS_SETTINGS } from './config';

export default {
  name: 'JobsTable',

  inheritAttrs: false,

  components: {
    JobDetailsCard,
  },

  data: () => ({
    headers: [
      {
        text: '',
        value: 'action',
        sortable: false,
        width: '144px',
      },
      { text: 'Id', value: 'key' },
      { text: 'Status', value: 'status' },
      { text: 'Cron', value: 'cron' },
      { text: 'Última execução', value: 'lastRunAt' },
      { text: 'Detalhes', value: 'data-table-expand' },
    ],
  }),

  methods: {
    formatDate: (dateStr) => (dateStr ? (new Date(dateStr)).toLocaleString('pt-br') : null),
    getStatusColor: (status) => STATUS_SETTINGS[status]?.color ?? 'grey lighten-1',
    getStatusText: (status) => STATUS_SETTINGS[status]?.text ?? '---',
  },
};
</script>
