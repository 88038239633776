<template>
  <v-card outlined>
    <v-card-title>
      {{ data.name }}
    </v-card-title>

    <v-card-subtitle>
      {{ data.repeatIntervalString }}
    </v-card-subtitle>

    <v-card-text>
      <template v-for="status of statusFlags">
        <v-chip
          small
          class="mx-1"
          label
          :disabled="!status.value"
          :color="status.value ? status.color : ''"
          :key="status.text"
        >
          {{ status.text }}
        </v-chip>
      </template>
    </v-card-text>

    <template v-if="data.failed">
      <v-divider class="mx-4" />

      <v-card-text>
        <v-list-item three-line>
          <v-list-item-icon>
            <v-icon color="error">mdi-alert-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ data.failReason }}
            </v-list-item-title>

            <v-list-item-subtitle>
              Em {{ data.failedAt }}
            </v-list-item-subtitle>

            <v-list-item-subtitle>
              Total de falhas: {{ data.failCount}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </template>

    <v-divider class="mx-4" />

    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <p class="text-subtitle-1">
            <v-icon left>
              mdi-clock-outline
            </v-icon>

            <span style="vertical-align: middle;">Última execução</span>
          </p>

          <ul>
            <li>
              <span class="font-weight-bold">Iniciada em:</span>
              {{ data.lastRunAt }}
            </li>

            <li>
              <span class="font-weight-bold">Finalizada em:</span>
              {{ data.lastFinishedAt }}
            </li>

            <li>
              <span class="font-weight-bold">Acionada por:</span>
              {{ data.lastModifiedBy }}
            </li>
          </ul>
        </v-col>

        <v-col cols="12" sm="6">
          <p class="text-subtitle-1">
            <v-icon left size="18">
              mdi-repeat
            </v-icon>

            <span style="vertical-align: middle;">Repetição</span>
          </p>

          <ul>
            <li>
              <span class="font-weight-bold mr-1">Cron:</span>
              <code>{{ data.repeatInterval }}</code>
            </li>

            <li>
              <span class="font-weight-bold">Intervalo:</span>
              {{ data.repeatIntervalString }}
            </li>

            <li>
              <span class="font-weight-bold">Fuso horário:</span>
              {{ data.repeatTimezone }}
            </li>

            <li>
              <span class="font-weight-bold">Próxima execução:</span>
              {{ data.nextRunAt }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<!--
  TODO: Display info about:

  - data.lockedAt
  - data.type?
  - data.priority?
  - data.serviceName?
-->

<script>
import { STATUS_SETTINGS } from './config';

export default {
  name: 'RoutineDetails',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statusFlags: (vm) => Object.values(STATUS_SETTINGS).map((status) => ({
      text: status.text,
      color: status.color,
      value: !!vm.data[status.key],
    })),
  },
};
</script>
