<template>
  <v-timeline-item small fill-dot :color="statusColor">
    <v-card outlined>
      <v-card-text>
        <ul>
          <li>
            <span class="font-weight-bold">Status:</span>

            <v-chip x-small label class="ml-2" :color="statusColor">
              {{ statusText }}
            </v-chip>
          </li>

          <li>
            <span class="font-weight-bold">Iniciada em:</span>
            {{ startedAtString }}
          </li>

          <li>
            <span class="font-weight-bold">Finalizada em:</span>
            {{ finishedAtString }}
          </li>

          <li>
            <span class="font-weight-bold">Intervalo:</span>
            {{ humanReadableCron }}
          </li>

          <li>
            <span class="font-weight-bold mr-1">Cron:</span>
            <code>{{ data.cron }}</code>
          </li>

          <li>
            <span class="font-weight-bold">Fuso horário:</span>
            {{ data.timezone }}
          </li>
        </ul>

        <v-menu top :close-on-content-click="false">
          <template v-slot:activator="{ on: menuListeners, attrs: menuAttrs }">
            <v-btn
              x-small
              depressesd
              color="primary"
              class="mt-2 ml-6"
              v-on="menuListeners"
              v-bind="menuAttrs"
            >
              <v-icon left x-small>
                mdi-text
              </v-icon>

              Ver mensagem de retorno
            </v-btn>
          </template>

          <template v-slot:default>
            <v-card>
              <v-card-text class="primary--text">
                <pre>{{ returnMessageString }}</pre>
              </v-card-text>
            </v-card>
          </template>
        </v-menu>
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import cronstrue from 'cronstrue/i18n';
import { isValidCron } from 'cron-validator';

import { STATUS_SETTINGS } from './config';

const formatDate = (dateStr) => (dateStr ? (new Date(dateStr)).toLocaleString('pt-br') : null);

export default {
  name: 'JobHistoryHistoryEntry',

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    humanReadableCron: (vm) => (isValidCron(vm.data.cron ?? '')
      ? cronstrue.toString(vm.data.cron, { locale: 'pt_BR' })
      : 'Cron inválida'
    ),

    statusColor: (vm) => STATUS_SETTINGS[vm.data.status]?.color ?? 'grey lighten-1',
    statusText: (vm) => STATUS_SETTINGS[vm.data.status]?.text ?? '---',

    startedAtString: (vm) => formatDate(vm.data.startedAt),
    finishedAtString: (vm) => formatDate(vm.data.finishedAt),
    // NOTE: Both fields always return non-nullish values
    lastRunReturnMessage: (vm) => vm.data.returnMessage || vm.data.failReason,
    returnMessageString: (vm) => {
      const msg = vm.lastRunReturnMessage;

      try {
        return JSON.stringify(JSON.parse(msg), undefined, 2);
      } catch (e) {
        return msg;
      }
    },
  },
};
</script>
