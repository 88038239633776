var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('td',{staticClass:"primary white--text",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(group || 'Rotinas gerais')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"green","small":"","disabled":item.status === 'running'},on:{"click":function () { return _vm.$emit('click:run', item); }}},[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue","small":""},on:{"click":function () { return _vm.$emit('click:edit', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"red","small":""},on:{"click":function () { return _vm.$emit('click:remove', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" ")])]}},{key:"item.cron",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipListeners = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","left":"","color":item.active ? 'primary' : 'error'}},'v-icon',tooltipAttrs,false),tooltipListeners),[_vm._v(" "+_vm._s(item.active ? 'mdi-alarm' : 'mdi-alarm-off')+" ")])]}},{key:"default",fn:function(){return [_vm._v(" "+_vm._s(item.active ? 'Rotina agendada' : 'Rotina desligada')+" ")]},proxy:true}],null,true)}),_c('code',[_vm._v(_vm._s(item.cron))])]}},{key:"item.lastRunAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.lastRunAt) || 'Nunca executado')+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('job-details-card',{attrs:{"data":item}})],1)]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }