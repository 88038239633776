<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="queues"
          :loading="loading"
          :items-per-page="100"
          :disable-pagination="true"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.state`]="{ item }">
            <v-chip
              x-small
              :color="item.state === 'running' ? 'green' : 'red'"
              dark
              >{{ item.state }}</v-chip
            >
          </template>
          <template v-slot:[`item.messages_ready`]="{ item }">
            {{ item.messages_ready }}
          </template>
          <template v-slot:[`item.messages_unacknowledged`]="{ item }">
            {{ item.messages_unacknowledged }}
          </template>
          <template v-slot:[`item.messages`]="{ item }">
            {{ item.messages }}
          </template>

          <template v-slot:[`item.income`]="{ item }">
            {{ item.income }}/s
          </template>

          <template v-slot:[`item.deliver`]="{ item }">
            {{ item.deliver }}/s
          </template>

          <template v-slot:[`item.ackno`]="{ item }">
            {{ item.ackno }}/s
          </template>

          <template v-slot:[`item.estimated`]="{ item }">
            {{ getEstimatedTime(item) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 5000,
      },
    ]),
  ],

  data: () => ({
    loading: true,
    queues: [],
    interval: '',
    error: '',
    headers: [
      { text: 'Queue', value: 'name' },
      { text: 'State', value: 'state' },
      { text: 'Ready', value: 'messages_ready', align: 'end' },
      { text: 'Unack', value: 'messages_unacknowledged', align: 'end' },
      { text: 'Incoming', value: 'income', align: 'end' },
      { text: 'Deliver', value: 'deliver', align: 'end' },
      { text: 'Ack', value: 'ackno', align: 'end' },
      {
        text: 'Estimated time',
        value: 'estimated',
        align: 'end',
        sortable: false,
      },
    ],
  }),

  methods: {
    async getData() {
      this.loading = true;

      try {
        const { data } = await api.admin.broker.queues();

        this.queues = data.map((queue) => ({
          ...queue,
          income: queue.message_stats?.publish_details?.rate ?? 0,
          deliver: queue.message_stats?.deliver_details?.rate ?? 0,
          ackno: queue.message_stats?.ack_details?.rate ?? 0,
        }));
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    getEstimatedTime(item) {
      if (!item.deliver) {
        return item.messages_ready ? `> ${this.getTimeString(item.messages_ready)}` : '0s';
      }

      const estimatedSeconds = Math.floor(item.messages_ready / item.deliver);

      return this.getTimeString(estimatedSeconds);
    },

    getTimeString(totalSeconds) {
      const padNumber = (val) => String(val).padStart(2, 0);

      const totalMinutes = Math.floor(totalSeconds / 60);
      const totalHours = Math.floor(totalMinutes / 60);

      const days = Math.floor(totalHours / 24);
      const hours = totalHours % 24;
      const minutes = totalMinutes % 60;
      const seconds = totalSeconds % 60;

      if (days > 0) {
        return `${days}d ${padNumber(hours)}h ${padNumber(minutes)}m ${padNumber(seconds)}s`;
      }

      if (totalHours > 0) {
        return `${hours}h ${padNumber(minutes)}m ${padNumber(seconds)}s`;
      }

      if (totalMinutes > 0) {
        return `${minutes}m ${padNumber(seconds)}s`;
      }

      return `${seconds}s`;
    },
  },
};
</script>
