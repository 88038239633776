<template>
  <v-container fluid>
    <v-row>
      <template v-for="item of overviewItems">
        <v-col cols="6" sm="4" md="2" :key="item.key">
          <v-card dark :color="item.color" :loading="isLoading && 'primary'">
            <v-card-title class="justify-center text-h4 font-weight-black">
              {{ item.count }}
            </v-card-title>

            <v-card-subtitle class="text-center text-subtitle-1 font-weight-light">
              {{ item.text }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'RoutinesOverview',

  props: {
    data: {
      type: Object,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    overviewItems: (vm) => [
      {
        text: 'Total',
        key: 'totalUnique',
        count: vm.data?.totalUnique ?? 0,
        color: 'deep-purple darken-1',
      },

      {
        text: 'Finalizados',
        key: 'completed',
        count: vm.data?.completed ?? 0,
        color: 'green darken-2',
      },

      {
        text: 'Com erro',
        key: 'failed',
        count: vm.data?.failed ?? 0,
        color: 'red darken-2',
      },

      {
        text: 'Executando',
        key: 'running',
        count: vm.data?.running ?? 0,
        color: 'yellow darken-3',
      },

      {
        text: 'Agendado',
        key: 'scheduled',
        count: vm.data?.scheduled ?? 0,
        color: 'blue-grey darken-1',
      },

      {
        text: 'Na fila',
        key: 'queued',
        count: vm.data?.queued ?? 0,
        color: 'blue-grey darken-2',
      },

      // {
      //   text: 'Repetição',
      //   key: 'repeating',
      //   count: vm.data?.repeating ?? 0,
      //   color: 'blue-grey darken-3',
      // },
    ],
  },
};
</script>
