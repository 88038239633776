<template>
  <v-form
    ref="form"
    v-model="isFormValid"
    :disabled="isLoading"
    @submit.prevent="() => submitForm()"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            label="Id"
            hint="Campo obrigatório"
            persistent-hint

            v-model="formFields.key"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Descrição"
            v-model="formFields.description"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            label="Grupo"
            v-model="formFields.group"
          />
        </v-col>

        <v-col cols="12" md="4">
          <v-switch
            class="px-2 mt-0"
            hide-details

            v-model="formFields.active"
          >
            <template v-slot:label>
              <v-divider vertical class="mx-2" />

              <div class="px-2">
                <p class="text-subtitle-1 mb-0">
                  Habilitar rotina
                </p>

                <p class="text-caption grey--text mb-0">
                  Executar a ação no intervalo determinado
                </p>
              </div>
            </template>
          </v-switch>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="Cron"
            :hint="humanReadableCron"
            persistent-hint

            v-model="formFields.cron"
            :rules="[vRules.requiredField, vRules.cronExpression]"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            label="Fuso horário"
            v-model="formFields.timezone"
            readonly
          />
        </v-col>
      </v-row>

      <v-divider class="my-2" />

      <v-row>
        <v-col cols="12">
          <p class="text-subtitle-2 mb-0">Config. Webhook</p>
        </v-col>

        <v-col cols="12" sm="3">
          <v-select
            label="Serviço alvo"
            v-model="formFields.webhookConfig.service"
            :items="serviceList"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <v-select
            label="Método HTTP"
            v-model="formFields.webhookConfig.method"
            :items="httpMethods"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Endpoint"
            hint="Campo obrigatório"
            persistent-hint

            v-model="formFields.webhookConfig.endpoint"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Headers"
            auto-grow
            rows="1"

            v-model="formFields.webhookConfig.headers"
            :rules="[vRules.validJSON]"
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            label="Body"
            auto-grow
            rows="1"
            :disabled="formFields.webhookConfig.method !== 'post'"

            v-model="formFields.webhookConfig.body"
            :rules="[vRules.validJSON]"
          />
        </v-col>
      </v-row>

      <v-row dense justify="end">
        <v-col cols="12" sm="auto">
          <v-btn
            text
            block
            color="error"
            :disabled="isLoading"
            @click="() => discardHandler()"
          >
            {{ texts.discardBtn }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="auto">
          <v-btn
            block
            color="primary"
            type="submit"
            :disabled="!isFormValid || isLoading"
            :loading="isLoading"
          >
            {{ texts.submitBtn }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import cronstrue from 'cronstrue/i18n';
import { cloneDeep } from 'lodash';
import { isValidCron } from 'cron-validator';

import { cronExpression, requiredField, validJSON } from '@/utils/validators';
import fillSchema from '@/utils/fill-schema';

const formSchema = {
  key: '',
  description: '',
  group: '',

  active: false,
  cron: '* * * * *',
  timezone: 'America/Sao_Paulo',

  type: 'webhook',
  webhookConfig: {
    endpoint: '',
    method: 'post',
    service: 'external',
    body: null,
    headers: null,
  },
  // brokerConfig: null,
};

export default {
  name: 'JobForm',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: null,
    },

    submitBtnText: {
      type: String,
      default: null,
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.initialData ?? {}),
    isFormValid: false,

    vRules: {
      cronExpression,
      requiredField,
      validJSON,
    },

    httpMethods: ['get', 'post'].map((item) => ({ text: item.toUpperCase(), value: item })),

    serviceList: [
      { text: 'Assets', value: 'assets' },
      { text: 'B3', value: 'b3' },
      { text: 'COMDINHEIRO', value: 'comdinheiro' },
      { text: 'Currencies', value: 'currencies' },
      { text: 'Emails', value: 'emails' },
      { text: 'ETL', value: 'etl' },
      { text: 'Informer', value: 'informer' },
      { text: 'Operator', value: 'operator' },
      { text: 'Portfolios', value: 'portfolios' },
      { text: 'Quant', value: 'quant' },
      { text: 'Reporting', value: 'reporting' },
      { text: 'Risk', value: 'risk' },
      { text: 'Stocks', value: 'stocks' },
      { text: 'UP2DATA', value: 'up2data' },
      { text: 'Users', value: 'users' },
      { text: 'Global Portfolios', value: 'globalPortfolios' },
      { text: 'URL Externa', value: 'external' },
    ],
  }),

  computed: {
    texts: (vm) => ({
      discardBtn: vm.discardBtnText ?? 'Descartar alterações',
      submitBtn: vm.submitBtnText ?? 'Enviar',
    }),

    humanReadableCron: (vm) => (isValidCron(vm.formFields.cron ?? '')
      ? cronstrue.toString(vm.formFields.cron, { locale: 'pt_BR' })
      : 'Cron inválida'
    ),
  },

  methods: {
    discardHandler() {
      this.discardChanges();
      this.$emit('click:discard');
    },

    discardChanges() {
      this.formFields = fillSchema(formSchema, this.initialData ?? {});
    },

    submitForm() {
      this.$emit('submit', cloneDeep(this.formFields));
    },
  },
};
</script>
