<template>
  <v-container grid-list-md>
    <v-row>
      <v-col>
        <v-chip
          class="mt-3"
          v-if="userId"
          color="primary"
          close
          label
          text-color="white"
          @click:close="filterUser(undefined)"
        >
          Usuário: {{ userId }}
        </v-chip>
      </v-col>

      <v-col>
        <v-menu
          ref="menu"
          class="ma-0 pa-0"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateQuery"
              label="Filtrar data"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" range>
            <v-btn text color="red" @click="dateMenu = false"> Fechar </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(date);
                filterDate();
              "
            >
              Filtrar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-text-field
          v-model="url"
          append-icon="mdi-magnify"
          label="Filtrar URL"
          class="mr-5"
          single-line
          hide-details
          @input="isTyping = true"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      class="elevation-1 mt-3"
      :headers="headers"
      :items="logs"
      :loading="loading"

      disable-sort
      :group-desc="true"

      :server-items-length="totalLogs"
      :footer-props="footerProps"
      :items-per-page="50"

      @update:page="changePage"
    >
      <template v-slot:item.date="{ item }">
        <div class="text-no-wrap">
          {{ new Date(item.date).toLocaleString() }}
        </div>
      </template>

      <template v-slot:item.user="{ item }">
        <div style="cursor: pointer" @click="filterUser(item.userId)">
          {{ item.user.name }}
        </div>
      </template>

      <template v-slot:item.response="{ item }">
        <v-chip small :color="item.response && item.response.status >= 300 ? 'error' : 'success'">
          {{ item.response ? item.response.status : 200 }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon color="primary" @click="() => showDetails(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <user-log-details
      max-width="900"
      scrollable
      :item="selectedItem"
      v-model="isDetailsDialogOpen"
    />
  </v-container>
</template>

<style scoped>
.name-link {
  color: #000;
  text-decoration: none;
  display: block;
}
</style>

<script>
import _ from 'lodash';
import api from '@/services/api';

import UserLogDetails from './UserLogDetails.vue';

export default {
  components: {
    UserLogDetails,
  },

  data: () => ({
    headers: [
      { text: 'Nome', value: 'user' },
      { text: 'Método', value: 'method' },
      { text: 'URL', value: 'originalUrl' },
      { text: 'Status', value: 'response' },
      { text: 'Data', value: 'date' },
      { text: 'Ver', value: 'actions' },
    ],
    loading: true,
    dateMenu: false,
    dateQuery: '',
    userId: undefined,
    date: [],
    logs: [],
    totalLogs: 0,
    page: 1,
    dataInterval: null,
    url: undefined,
    isTyping: false,
    footerProps: {
      'disable-items-per-page': true,
      'items-per-page-options': [50],
    },

    isDetailsDialogOpen: false,
    selectedItem: null,
  }),

  async mounted() {
    const {
      userId, url, page, date,
    } = this.$route.query;
    this.userId = userId;
    this.url = url;
    this.page = page;
    this.dateQuery = date;
    await this.getData();
  },

  watch: {
    url: _.debounce(function searchWatcher() {
      this.isTyping = false;
    }, 500),

    async isTyping(value) {
      if (!value) {
        if (this.url.length === 0) {
          await this.getData();
          return;
        }
        await this.getData(1);
      }
    },

    date(date) {
      const newDate = [...date];
      this.dateQuery = newDate.sort().join(':');
    },

    isDetailsDialogOpen(newState) {
      if (!newState) this.selectedItem = null;
    },
  },

  methods: {
    showDetails(item) {
      this.selectedItem = item;
      this.isDetailsDialogOpen = true;
    },

    changeUrl() {
      this.$router.push({
        query: {
          userId: this.userId,
          url: this.url,
          page: this.page,
          date: this.dateQuery,
        },
      });
    },

    filterDate() {
      this.getData();
    },

    async filterUser(userId) {
      this.userId = userId;
      await this.getData();
    },

    async changePage(page) {
      this.page = page;
      await this.getData();
    },

    async getData() {
      try {
        this.logs = [];
        this.loading = true;
        const { data } = await api.users.logs.list({
          params: {
            url: this.url,
            page: this.page,
            userId: this.userId,
            limit: 20,
            date: this.dateQuery,
          },
        });

        const { logs, total } = data;
        this.logs = logs;
        this.totalLogs = total;
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.changeUrl();
      }
    },
  },
};
</script>
