<!--
  TODO:
    - Add webhookConfig information
    - Add jobHistory information
-->

<template>
  <v-card outlined>
    <v-card-title>
      {{ data.key }}

      <v-spacer />

      <v-btn icon @click="() => showJobHistory()">
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ data.description }}
    </v-card-subtitle>

    <template v-if="data.status === 'failed'">
      <v-divider class="mx-4" />

      <v-card-text>
        <v-list-item two-line>
          <v-list-item-icon>
            <v-icon color="error">
              mdi-alert-circle-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ returnMessageString }}
            </v-list-item-title>

            <v-list-item-subtitle>
              Em {{ lastRunFinishedAtString }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </template>

    <v-divider class="mx-4" />

    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <p class="text-subtitle-1">
            <v-icon left size="18">
              mdi-repeat
            </v-icon>

            <span style="vertical-align: middle;">Repetição</span>
          </p>

          <ul>
            <li>
              <span class="font-weight-bold">Intervalo:</span>
              {{ humanReadableCron }}
            </li>

            <li>
              <span class="font-weight-bold mr-1">Cron:</span>
              <code>{{ data.cron }}</code>
            </li>

            <li>
              <span class="font-weight-bold">Fuso horário:</span>
              {{ data.timezone }}
            </li>

            <li>
              <span class="font-weight-bold">Próxima execução:</span>
              {{ nextRunAtString }}
            </li>
          </ul>
        </v-col>

        <v-col cols="12" sm="6">
          <p class="text-subtitle-1">
            <v-icon left>
              mdi-clock-outline
            </v-icon>

            <span style="vertical-align: middle;">Última execução</span>
          </p>

          <template v-if="!data.lastRunAt">
            <p>Nunca executado.</p>
          </template>

          <template v-else>
            <ul>
              <li>
                <span class="font-weight-bold">Status:</span>

                <v-chip x-small label class="ml-2" :color="statusColor">
                  {{ statusText }}
                </v-chip>
              </li>

              <li>
                <span class="font-weight-bold">Iniciada em:</span>
                {{ lastRunAtString }}
              </li>

              <li>
                <span class="font-weight-bold">Finalizada em:</span>
                {{ lastRunFinishedAtString }}
              </li>
            </ul>

            <v-menu top :close-on-content-click="false">
              <template v-slot:activator="{ on: menuListeners, attrs: menuAttrs }">
                <v-btn
                  x-small
                  depressesd
                  color="primary"
                  class="mt-2 ml-6"
                  v-on="menuListeners"
                  v-bind="menuAttrs"
                >
                  <v-icon left x-small>
                    mdi-text
                  </v-icon>

                  Ver mensagem de retorno
                </v-btn>
              </template>

              <template v-slot:default>
                <v-card>
                  <v-card-text class="primary--text">
                    <pre>{{ returnMessageString }}</pre>
                  </v-card-text>
                </v-card>
              </template>
            </v-menu>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="isHistoryDialogOpen" max-width="600" scrollable>
      <v-card tile>
        <v-system-bar class="justify-end">
          <v-icon @click.stop="() => isHistoryDialogOpen = false">
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-card-title>
          Histórico de execuções: "{{ data.key }}"
        </v-card-title>

        <v-card-subtitle>
          {{ data.description }}
        </v-card-subtitle>

        <v-progress-linear indeterminate :active="isFetchingHistory" />

        <v-divider/>

        <v-card-text class="pt-4" v-if="!isFetchingHistory && !sortedJobHistory.length">
          Nunca executado.
        </v-card-text>

        <v-card-text v-if="sortedJobHistory.length > 0">
          <v-timeline dense>
            <template v-for="entry of sortedJobHistory">
              <job-history-history-entry :key="entry._id" :data="entry" />
            </template>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import cronstrue from 'cronstrue/i18n';
import { isValidCron } from 'cron-validator';
import { orderBy } from 'lodash';
import api from '@/services/api';

import { STATUS_SETTINGS } from './config';
import JobHistoryHistoryEntry from './JobHistoryHistoryEntry.vue';

const formatDate = (dateStr) => (dateStr ? (new Date(dateStr)).toLocaleString('pt-br') : null);

export default {
  name: 'JobDetailsCard',

  components: {
    JobHistoryHistoryEntry,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isHistoryDialogOpen: false,
    isFetchingHistory: false,
    jobHistory: [],
  }),

  computed: {
    humanReadableCron: (vm) => (isValidCron(vm.data.cron ?? '')
      ? cronstrue.toString(vm.data.cron, { locale: 'pt_BR' })
      : 'Cron inválida'
    ),

    statusColor: (vm) => STATUS_SETTINGS[vm.data.status]?.color ?? 'grey lighten-1',
    statusText: (vm) => STATUS_SETTINGS[vm.data.status]?.text ?? '---',

    nextRunAtString: (vm) => (vm.data.active ? formatDate(vm.data.nextRunAt) : 'Rotina desligada.'),
    lastRunAtString: (vm) => formatDate(vm.data.lastRunAt),
    lastRunFinishedAtString: (vm) => formatDate(vm.data.lastRunFinishedAt),
    // NOTE: Both fields always return non-nullish values
    lastRunReturnMessage: (vm) => vm.data.returnMessage || vm.data.failReason,
    returnMessageString: (vm) => {
      const msg = vm.lastRunReturnMessage;

      try {
        return JSON.stringify(JSON.parse(msg), undefined, 2);
      } catch (e) {
        return msg;
      }
    },

    sortedJobHistory: (vm) => orderBy(vm.jobHistory, 'startedAt', 'desc'),
  },

  methods: {
    showJobHistory() {
      this.isHistoryDialogOpen = true;
      this.fetchJobHistory();
    },

    // TODO: Find a better place for it
    async fetchJobHistory() {
      this.isFetchingHistory = true;

      try {
        const { data } = await api.admin.scheduler.getJobHistory(this.data._id);
        this.jobHistory = data.history;
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetchingHistory = false;
      }
    },
  },
};
</script>
