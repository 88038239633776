<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="_id"
    show-expand
  >
    <template v-slot:group.header="{ group, headers }">
      <td class="primary white--text" :colspan="headers.length">
        Serviço: {{group}}
      </td>
    </template>

    <template v-slot:item.action="{ item }">
      <v-btn text icon color="blue" small @click="() => $emit('click:requeue', item)">
        <v-icon small>mdi-reload</v-icon>
      </v-btn>

      <v-btn text icon color="red" small @click="() => $emit('click:delete', item)">
        <v-icon small>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>

    <template v-slot:item.repeatInterval="{ item }">
      <code>{{ item.repeatInterval }}</code>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip :color="getStatusColor(item.status)" small label>
        {{ getStatusText(item.status) }}

        <v-tooltip top v-if="item.lockedAt">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar right v-on="on" v-bind="attrs">
              <v-icon small>mdi-lock</v-icon>
            </v-avatar>
          </template>

          <template v-slot:default>
            Bloqueado em: {{ item.lockedAt }}
          </template>
        </v-tooltip>
      </v-chip>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-2">
        <routine-details :data="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import RoutineDetails from './RoutineDetails.vue';
import { STATUS_SETTINGS } from './config';

export default {
  name: 'RoutinesTable',

  inheritAttrs: false,

  components: {
    RoutineDetails,
  },

  data: () => ({
    headers: [
      {
        text: '',
        value: 'action',
        sortable: false,
        width: '96px',
      },
      { text: 'Nome', value: 'name' },
      { text: 'Serviço', value: 'serviceName' },
      { text: 'Status', value: 'status' },
      { text: 'Cron', value: 'repeatInterval', sortable: false },
      { text: 'Última execução', value: 'lastRunAt' },
      { text: 'Detalhes', value: 'data-table-expand' },
    ],
  }),

  methods: {
    getStatusColor: (status) => (STATUS_SETTINGS[status]?.color ?? 'grey lighten-1'),
    getStatusText: (status) => (STATUS_SETTINGS[status]?.text ?? '---'),
  },
};
</script>
