/* eslint-disable import/prefer-default-export */
export const STATUS_SETTINGS = {
  failed: {
    key: 'failed',
    text: 'Com erro',
    color: 'error',
  },

  running: {
    key: 'running',
    text: 'Executando',
    color: 'info',
  },

  completed: {
    key: 'completed',
    text: 'Finalizado',
    color: 'success',
  },

  queued: {
    key: 'queued',
    text: 'Na fila',
    color: 'primary',
  },

  scheduled: {
    key: 'scheduled',
    text: 'Agendado',
    color: 'blue-grey lighten-3',
  },
};
