<template>
  <v-container class="scheduler-view">
    <h1 class="text-h5 mb-4">Rotinas</h1>

    <div class="text-right mb-4">
      <v-btn color="primary" small rounded @click="() => showFormDialog(null)">
        <v-icon left>
          mdi-plus
        </v-icon>

        Criar rotina
      </v-btn>
    </div>

    <v-dialog persistent max-width="900" v-model="isFormDialogOpen">
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="py-0">
          <job-form
            ref="jobForm"
            discard-btn-text="Cancelar"
            :is-loading="isSendingInfo"
            :initial-data="currentJob"
            @click:discard="() => hideFormDialog()"
            @submit="(fields) => submitForm(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card>
      <jobs-table
        fixed-header
        hide-default-footer

        :items="jobList"
        :loading="isFetchingData"

        :items-per-page="-1"
        group-by="group"
        sort-by="key"
        must-sort

        @click:run="(item) => triggerJob(item._id)"
        @click:edit="(item) => showFormDialog(item)"
        @click:remove="(item) => showDeleteConfirmation(item)"
      />
    </v-card>

    <v-dialog max-width="300" v-model="isRemoveConfirmationOpen">
      <v-card>
        <v-card-title>
          Excluir rotina
        </v-card-title>

        <v-card-text>
          Tem certeza que quer excluir a rotina
          <span class="font-weight-bold">{{ currentJob && currentJob.key }}</span>
          ?
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            small
            text
            color="error"
            :disabled="isDeletingJob"
            @click="() => hideDeleteConfirmation()"
          >
            Cancelar
          </v-btn>

          <v-btn
            small
            color="primary"
            :loading="isDeletingJob"
            @click="() => deleteJob(currentJob && currentJob._id)"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

import JobForm from './JobForm.vue';
import JobsTable from './JobsTable.vue';

export default {
  name: 'SchedulerView',

  components: {
    JobForm,
    JobsTable,
  },

  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.fetchData(),
        interval: 15000,
      },
    ]),
  ],

  data: () => ({
    isFetchingData: false,
    jobList: [],

    isFormDialogOpen: false,
    currentJob: null,
    isSendingInfo: false,

    isRemoveConfirmationOpen: false,
    isDeletingJob: false,
  }),

  computed: {
    dialogTitle: (vm) => (vm.currentJob ? 'Editar rotina' : 'Nova rotina'),
  },

  methods: {
    showFormDialog(currentJob) {
      this.currentJob = currentJob;
      this.isFormDialogOpen = true;

      if (this.$refs.jobForm) {
        this.$nextTick(() => this.$refs.jobForm.discardChanges());
      }
    },

    hideFormDialog() {
      this.currentJob = null;
      this.isFormDialogOpen = false;

      if (this.$refs.jobForm) {
        this.$nextTick(() => this.$refs.jobForm.discardChanges());
      }
    },

    showDeleteConfirmation(currentJob) {
      this.currentJob = currentJob;
      this.isRemoveConfirmationOpen = true;
    },

    hideDeleteConfirmation() {
      this.currentJob = null;
      this.isRemoveConfirmationOpen = false;
    },

    async fetchData() {
      this.isFetchingData = true;

      try {
        const { data: jobList } = await api.admin.scheduler.getJobs();

        this.jobList = jobList;
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async triggerJob(jobId) {
      try {
        await api.admin.scheduler.triggerJob(jobId);
        await this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: [error.response?.data.message ?? error.message],
        });
      }
    },

    async deleteJob(jobId) {
      this.isDeletingJob = true;

      try {
        await api.admin.scheduler.deleteJob(jobId);
        await this.fetchData();
        this.hideDeleteConfirmation();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isDeletingJob = false;
      }
    },

    async submitForm(fields) {
      this.isSendingInfo = true;

      try {
        if (this.currentJob) {
          await api.admin.scheduler.updateJob(this.currentJob._id, fields);
        } else {
          await api.admin.scheduler.createJob(fields);
        }

        await this.fetchData();
        this.hideFormDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: [error.response?.data.message ?? error.message],
        });
      } finally {
        this.isSendingInfo = false;
      }
    },
  },
};
</script>
