var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('h1',{staticClass:"text-h5"},[_vm._v("Usuários")]),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.users,"items-per-page":20,"expand-icon":"mdi-plus-circle","group-desc":true,"sort-by":['online', 'name'],"sort-desc":[false, true],"multi-sort":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.online",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.online === 'true' ? 'green' : 'red'}},[_vm._v("mdi-power")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"name-link",attrs:{"to":("/admin/users/" + (item._id))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.page",fn:function(ref){
var item = ref.item;
return [(!item.status.page || item.status.page.length === 0)?_c('span',[_vm._v(" --- ")]):_c('span',[_vm._v(" "+_vm._s(item.status.page[0].path)+" @ "+_vm._s(_vm.Moment(item.status.page[0].date).format('DD/MM/YYYY HH:mm'))+" ")])]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Moment(item.lastLogin).format('DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Moment(item.createdAt).format('DD/MM/YYYY'))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }