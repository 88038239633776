<template>
  <div>
    <v-progress-linear
        :indeterminate="loading"
        color="grey darken-2"
      ></v-progress-linear>
      <v-simple-table dark dense>
      <thead>
          <tr>
            <th class="text-left">Queue</th>
            <th class="text-left">Ready</th>
            <th class="text-left">Unack</th>
            <th class="text-left">Total</th>
            <th class="text-left">Incoming</th>
            <th class="text-left">Deliver</th>
            <th class="text-left">Ack</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ name }}</td>
            <td>{{messages.messagesReady}}</td>
            <td>{{messages.messagesUnacknowledged}}</td>
            <td>{{messages.messages}}</td>

            <td>{{messages.messagesUnacknowledgedDetailsRate}}</td>
            <td>{{messages.messagesReadyRate}}</td>
            <td>{{messages.messagesDetailsRate}}</td>
          </tr>
        </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 5000,
      },
    ]),
  ],

  props: {
    queue: String,
  },

  data: () => ({
    name: '',
    messages: {},
    interval: undefined,
    loading: true,
  }),

  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await api.admin.broker.queue(this.queue);
        this.name = data.name;
        this.messages = {
          messagesReady: data.messages_ready,
          messagesReadyRate: data.messages_ready_details.rate,
          messagesUnacknowledged: data.messages_unacknowledged,
          messagesUnacknowledgedDetailsRate: data.messages_unacknowledged_details.rate,
          messages: data.messages,
          messagesDetailsRate: data.messages_details.rate,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
