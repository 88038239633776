var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.queues,"loading":_vm.loading,"items-per-page":100,"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":item.state === 'running' ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.state))])]}},{key:"item.messages_ready",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.messages_ready)+" ")]}},{key:"item.messages_unacknowledged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.messages_unacknowledged)+" ")]}},{key:"item.messages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.messages)+" ")]}},{key:"item.income",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.income)+"/s ")]}},{key:"item.deliver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deliver)+"/s ")]}},{key:"item.ackno",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ackno)+"/s ")]}},{key:"item.estimated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEstimatedTime(item))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }