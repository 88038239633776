var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"_id","show-expand":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
return [_c('td',{staticClass:"primary white--text",attrs:{"colspan":headers.length}},[_vm._v(" Serviço: "+_vm._s(group)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"blue","small":""},on:{"click":function () { return _vm.$emit('click:requeue', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"red","small":""},on:{"click":function () { return _vm.$emit('click:delete', item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"item.repeatInterval",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.repeatInterval))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.status))+" "),(item.lockedAt)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"right":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}},{key:"default",fn:function(){return [_vm._v(" Bloqueado em: "+_vm._s(item.lockedAt)+" ")]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('routine-details',{attrs:{"data":item}})],1)]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }