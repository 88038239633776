<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" :value="value">
    <v-card>
      <v-card-title class="grey lighten-2">
        {{ cardTitle }}
      </v-card-title>

      <v-card-subtitle class="grey lighten-2">
        {{ urlDescription }}
      </v-card-subtitle>

      <v-card-text class="pt-4">
        <v-expansion-panels multiple>
          <!-- User -->
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header>
              Usuário
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table dense>
                <tbody>
                  <tr v-for="({ key, value }) of userInfo" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Headers -->
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header>
              Headers
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table dense>
                <thead>
                  <tr class="text-left">
                    <th>Header</th>
                    <th>Content</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="({ key, value }) of headersEntries" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Body -->
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header>
              Body
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <pre v-if="item && item.body">{{ requestBody }}</pre>
              <span v-else>No body</span>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Response -->
          <v-expansion-panel class="mb-2">
            <v-expansion-panel-header>
              Response
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <pre v-if="item && item.response">{{ responseBody }}</pre>
              <span v-else>No response</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn color="red" text @click="() => $emit('input', false)">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    cardTitle: (vm) => (vm.item ? `${vm.item.user?.name} - ${new Date(vm.item.date).toLocaleString()}` : ''),
    urlDescription: (vm) => (vm.item ? `${vm.item.method} ${vm.item.originalUrl}` : ''),

    headersEntries: (vm) => Object.entries(vm.item?.headers ?? {}).map(([key, value]) => ({ key, value })),
    userInfo: (vm) => Object.entries(vm.item?.user ?? {}).map(([key, value]) => ({ key, value })),

    requestBody: (vm) => (vm.item?.body ? JSON.stringify(vm.item.body, undefined, 2) : ''),
    responseBody: (vm) => (vm.item?.response ? JSON.stringify(vm.item.response, undefined, 2) : ''),
  },
};
</script>
