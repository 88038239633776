var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-row',[_c('v-col',[(_vm.userId)?_c('v-chip',{staticClass:"mt-3",attrs:{"color":"primary","close":"","label":"","text-color":"white"},on:{"click:close":function($event){return _vm.filterUser(undefined)}}},[_vm._v(" Usuário: "+_vm._s(_vm.userId)+" ")]):_vm._e()],1),_c('v-col',[_c('v-menu',{ref:"menu",staticClass:"ma-0 pa-0",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrar data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateQuery),callback:function ($$v) {_vm.dateQuery=$$v},expression:"dateQuery"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" Fechar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.date);
              _vm.filterDate();}}},[_vm._v(" Filtrar ")])],1)],1)],1),_c('v-col',[_c('v-text-field',{staticClass:"mr-5",attrs:{"append-icon":"mdi-magnify","label":"Filtrar URL","single-line":"","hide-details":""},on:{"input":function($event){_vm.isTyping = true}},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.logs,"loading":_vm.loading,"disable-sort":"","group-desc":true,"server-items-length":_vm.totalLogs,"footer-props":_vm.footerProps,"items-per-page":50},on:{"update:page":_vm.changePage},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(new Date(item.date).toLocaleString())+" ")])]}},{key:"item.user",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.filterUser(item.userId)}}},[_vm._v(" "+_vm._s(item.user.name)+" ")])]}},{key:"item.response",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.response && item.response.status >= 300 ? 'error' : 'success'}},[_vm._v(" "+_vm._s(item.response ? item.response.status : 200)+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.showDetails(item); }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])}),_c('user-log-details',{attrs:{"max-width":"900","scrollable":"","item":_vm.selectedItem},model:{value:(_vm.isDetailsDialogOpen),callback:function ($$v) {_vm.isDetailsDialogOpen=$$v},expression:"isDetailsDialogOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }