var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',[_vm._v("Health Check")]),_c('v-simple-table',{staticClass:"mt-3 main-table"},[_c('thead',[_c('th',[_vm._v("Dado")]),_c('th',[_vm._v("Com erro")]),_c('th',[_vm._v("Saudável")]),_c('th',[_vm._v("Total")])]),_c('tbody',[_c('tr',[_c('td',[_c('b',[_vm._v("Fundos 555")])]),_c('td',[_c('a',{on:{"click":function($event){return _vm.showList('fundsList', '')}}},[_vm._v(" "+_vm._s(_vm.fundsData.error)+" ")])]),_c('td',[_c('a',{on:{"click":function($event){return _vm.showList('fundsList', 'healthy')}}},[_vm._v(" "+_vm._s(_vm.fundsData.healthy)+" ")])]),_c('td',[_vm._v(_vm._s(_vm.fundsData.total))])]),_c('tr',[_c('td',[_c('b',[_vm._v("Índices")])]),_c('td',[_c('a',{on:{"click":function($event){return _vm.showList('indexesList', '')}}},[_vm._v(" "+_vm._s(_vm.indexesData.error)+" ")])]),_c('td',[_c('a',{on:{"click":function($event){return _vm.showList('indexesList', 'healthy')}}},[_vm._v(" "+_vm._s(_vm.indexesData.healthy)+" ")])]),_c('td',[_vm._v(_vm._s(_vm.indexesData.total))])])])]),(_vm.list.length > 0)?_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.list,"single-expand":true,"item-key":"_id","show-expand":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_vm._v(_vm._s(item.errors.missingDates))])])]}}],null,false,16606906)}):_vm._e(),_c('h3',{staticClass:"mt-3"},[_vm._v("Master Data Sync Check - "),_c('span',{staticStyle:{"font-size":"16px","font-weight":"300"}},[_vm._v("Última verificação: "+_vm._s(_vm.moment(_vm.date).format("DD/MM/YY HH:mm")))])]),_c('Queue',{attrs:{"queue":"reporting_sync_document"}}),_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.syncHealthHeaders,"items":_vm.syncHealthItems,"loading":_vm.syncHealthLoading,"item-class":_vm.colorRows,"disable-pagination":"","hide-default-footer":"","sort-by":"status"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"color":"red lighten-2","text":"","icon":"","small":""},on:{"click":function($event){return _vm.fullSyncRequest(item.name)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-sync ")])],1),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.w16 - item.sql)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }