<template>
    <v-container grid-list-md>
        <h1 class="text-h5">Usuários</h1>
        <v-data-table
            :headers="headers"
            :loading="loading"
            :items="users"
            :items-per-page="20"
            class="elevation-1 mt-3"
            expand-icon="mdi-plus-circle"
            :group-desc="true"
            :sort-by="['online', 'name']"
            :sort-desc="[false, true]"
            multi-sort
            :mobile-breakpoint="0"
            >
            <template v-slot:item.online="{ item }">
                <v-icon class="mr-1" :color="item.online === 'true' ? 'green' : 'red'">mdi-power</v-icon>
            </template>

            <template v-slot:item.name="{ item }">
              <router-link class="name-link" :to="`/admin/users/${item._id}`">
                {{item.name}}
              </router-link>
            </template>

            <template v-slot:item.page="{ item }">
              <span v-if="!item.status.page || item.status.page.length === 0">
                ---
              </span>
              <span v-else>
                {{item.status.page[0].path}} @
                {{Moment(item.status.page[0].date).format('DD/MM/YYYY HH:mm')}}
              </span>
            </template>

            <template v-slot:item.lastLogin="{ item }">
              {{Moment(item.lastLogin).format('DD/MM/YYYY HH:mm')}}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{Moment(item.createdAt).format('DD/MM/YYYY')}}
            </template>
        </v-data-table>
</v-container>
</template>

<style scoped>
.name-link {
  color: #000;
  text-decoration: none;
  display: block;
}
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

export default {
  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  data: () => ({
    headers: [
      { text: '', value: 'online', width: 80 },
      { text: 'Nome', value: 'name' },
      { text: 'Grupo', value: 'acl' },
      { text: 'Última Página', value: 'page' },
      { text: 'Email', value: 'email' },
      { text: 'Último Login', value: 'lastLogin' },
      { text: 'Criado em', value: 'createdAt' },
    ],
    Moment: moment,
    loading: true,
    overview: {},
    users: [],
    dataInterval: null,
  }),
  methods: {
    moment,
    async getData() {
      try {
        this.loading = true;
        const { data } = await api.users.list();
        this.overview = data.overview;
        this.users = data.users.map((user) => ({
          online: String(user.status.online),
          ...user,
        }));
        this.loading = false;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response
            ? error.response.data.error.message
            : error.message,
          color: 'error',
          status: true,
        });
      }
    },
  },
};
</script>
