<template>
  <v-container class="routines-view">
    <h1 class="text-h5 mb-4">Rotinas</h1>

    <routines-overview
      class="pa-0 mb-4"
      :data="jobsOverview"
      :is-loading="isLoading"
    />

    <v-card>
      <routines-table
        fixed-header

        :items="jobListParsed"
        :loading="isLoading"

        :items-per-page="-1"
        group-by="serviceName"
        group-desc
        must-sort
        sort-by="lastRunAt"
        sort-desc

        @click:requeue="(item) => requeueJob(item._id, item.serviceName)"
        @click:delete="(item) => deleteJob(item._id, item.serviceName)"
      />
    </v-card>
  </v-container>
</template>

<script>
import cronstrue from 'cronstrue/i18n';

import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';

import RoutinesOverview from './RoutinesOverview.vue';
import RoutinesTable from './RoutinesTable.vue';

const formatDate = (dateStr) => (dateStr ? (new Date(dateStr)).toLocaleString('pt-br') : null);

const formatCron = (cron) => (cron ? cronstrue.toString(cron, { locale: 'pt_BR' }) : 'Sem repetição');

const getJobStatus = (job) => {
  if (job.failed) return 'failed';
  if (job.running) return 'running';
  if (job.queued) return 'queued';
  if (job.completed) return 'completed';
  if (job.scheduled) return 'scheduled';
  return 'unknown';
};

export default {
  name: 'RoutinesView',

  components: {
    RoutinesOverview,
    RoutinesTable,
  },

  mixins: [
    jobScheduler([
      {
        job: (vm) => vm.getData(),
        interval: 15000,
      },
    ]),
  ],

  data: () => ({
    isLoading: false,
    jobsOverview: null,
    jobList: [],
  }),

  computed: {
    jobListParsed: (vm) => vm.jobList.map((job) => ({
      ...job,
      status: getJobStatus(job),
      lastRunAt: formatDate(job.lastRunAt),
      lastFinishedAt: formatDate(job.lastFinishedAt),
      nextRunAt: formatDate(job.nextRunAt),
      repeatInterval: job.repeatInterval || '- - - - -',
      repeatIntervalString: formatCron(job.repeatInterval),
      lockedAt: formatDate(job.lockedAt),
      failedAt: formatDate(job.failedAt),
    })),
  },

  methods: {
    async getData() {
      this.isLoading = true;

      try {
        const { data } = await api.admin.getRoutines();

        this.jobsOverview = data.overview;
        this.jobList = data.jobs;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response?.data.error.message ?? error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async requeueJob(jobId, service) {
      await api.admin.requeueRoutine({ jobId, service });
      await this.getData();
    },

    async deleteJob(jobId, service) {
      await api.admin.deleteRoutine({ jobId, service });
      await this.getData();
    },
  },
};
</script>
