<template>
  <v-container>
      <h3>Health Check</h3>
      <v-simple-table class="mt-3 main-table">
        <thead>
          <th>Dado</th>
          <th>Com erro</th>
          <th>Saudável</th>
          <th>Total</th>
        </thead>
        <tbody>
          <tr>
            <td><b>Fundos 555</b></td>
            <td>
              <a @click="showList('fundsList', '')">
                {{fundsData.error}}
              </a>
            </td>
            <td>
              <a @click="showList('fundsList', 'healthy')">
              {{fundsData.healthy}}
              </a>
              </td>
            <td>{{fundsData.total}}</td>
          </tr>
          <tr>
            <td><b>Índices</b></td>
            <td>
              <a @click="showList('indexesList', '')">
              {{indexesData.error}}
              </a>
            </td>
            <td>
              <a @click="showList('indexesList', 'healthy')">
              {{indexesData.healthy}}
              </a>
            </td>
            <td>{{indexesData.total}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-data-table
          v-if="list.length > 0"
          :headers="headers"
          :items="list"
          :single-expand="true"
          item-key="_id"
          show-expand
          class="elevation-1 mt-3"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div>{{ item.errors.missingDates }}</div>
            </td>
          </template>
      </v-data-table>
      <h3 class="mt-3">Master Data Sync Check - <span style="font-size:16px; font-weight: 300;">Última verificação: {{moment(date).format("DD/MM/YY HH:mm")}}</span></h3>
      <Queue queue="reporting_sync_document" />
      <v-data-table
      :headers="syncHealthHeaders"
      :items="syncHealthItems"
      :loading="syncHealthLoading"
      :item-class="colorRows"
      disable-pagination
      hide-default-footer
      sort-by="status"
      class="mt-2"
      >
      <template v-slot:item.name="{ item }">
        <v-btn
          class="ma-2"
          color="red lighten-2"
           text icon small
           @click="fullSyncRequest(item.name)"
        >
         <v-icon color="black">
          mdi-sync
          </v-icon>
        </v-btn>
        {{item.name}}
      </template>
      <template v-slot:item.status="{ item }">
         {{item.w16 - item.sql}}
        </template>
      </v-data-table>
  </v-container>
</template>

<style scoped>
.main-table a {
  text-decoration: underline;
}
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';
import jobScheduler from '@/mixins/job-scheduler';
import Queue from '@/components/admin/etl/Queue.vue';

export default {
  components: {
    Queue,
  },

  mixins: [
    jobScheduler([
      {
        job: (vm) => {
          vm.getData();
          vm.getSyncHealth();
        },
        interval: 30000,
      },
    ]),
  ],

  data: () => ({
    date: '',
    headers: [
      {
        text: 'id',
        align: 'start',
        sortable: false,
        value: '_id',
      },
      { text: 'Nome', value: 'name' },
      { text: 'Nº Erros', value: 'errorCount' },
      { text: '', value: 'data-table-expand' },
    ],
    interval: false,
    fundsData: {},
    indexesData: {},
    list: [],
    syncHealthHeaders: [
      { text: 'Dado', value: 'name' },
      { text: 'w16', value: 'w16' },
      { text: 'SQL', value: 'sql' },
      { text: 'Delta', value: 'status' },
    ],
    syncHealthItems: [],
    syncHealthLoading: false,
  }),

  methods: {
    moment,
    async fullSyncRequest(name) {
      const names = name.split('_');
      await api.admin.fullSyncCollection({
        database: names[0],
        collection: names[1],
        force: true,
        batchSize: 10000,
        delay: 10000,
      });
    },

    colorRows(item) {
      const delta = item.w16 - item.sql;
      if (delta > 0 || delta < 0) {
        return 'red lighten-5';
      }
      return 'green lighten-5';
    },

    async showList(method, type) {
      this.list = [];
      let params = {};
      if (type === 'healthy') {
        params = {
          healthy: 1,
        };
      }
      const res = await api.admin.data[method]({ params });
      this.list = res.data;
    },

    async getSyncHealth() {
      this.syncHealthLoading = true;

      try {
        const { data } = await api.admin.syncHealth();
        this.syncHealthItems = [];
        this.date = data.createdAt;
        Object.keys(data.report).forEach((key) => {
          this.syncHealthItems.push({
            name: key,
            sql: data.report[key].sql,
            w16: data.report[key].mongo,
            status: (data.report[key].mongo - data.report[key].sql === 0),
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.syncHealthLoading = false;
      }
    },

    async getData() {
      this.loading = true;

      try {
        const [fundsResponse, indexesResponse] = await Promise.all([
          api.admin.data.funds(),
          api.admin.data.indexes(),
        ]);

        this.fundsData = fundsResponse.data;
        this.indexesData = indexesResponse.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
